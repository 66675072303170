import React, { Component } from "react";
import { db } from "../services/firebase"
import eventBus from "./eventBus";
import {loadNotifications, loadSessions} from "./session";
import {req_get_all_sessions} from "./requests";
import BackdropAppear from "../components/staticDisplays/backdropAppear";
import AlertAppear from "../components/staticDisplays/alertAppear";

export default class Listener extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: localStorage.getItem("rtoken"),
            messages: [],
            readError: null,
            writeError: null,
            loading: false,
            alertOpen: false,
            alertObject: {open: false, message: "", type: "", vertical: "", horizontal: "", timeout: 5000}
        };
        this.handleChange = this.handleChange.bind(this);
        this.myRef = React.createRef();

        eventBus.on("updateSessions", (uObj) => {
            console.log("bus update sessions")
            loadSessions();
        });

        eventBus.on("showLoader", (uObj) => {
            //setLoading( uObj.showLoader );
            this.setState({loading: uObj.showLoader});
        });

        eventBus.on("showAlert", (uObj) => {
            console.log("in show alert")
            this.setState({ alertOpen: {...uObj} });
            //setAlertOpen( true );
            this.setState({ alertOpen: true });
        });

    }

    sendEvent = (message) => {
        //console.log("sending event", message)

        let pushEvent = message.split(":");

        //if (pushEvent[0] === "updateAssetMenu" && JSON.parse(localStorage.getItem("selectedAccount")) === pushEvent[0]){

        if (pushEvent[0] === "updateSessions"){
            loadSessions();
            //eventBus.dispatch(pushEvent[0], {  });
        }
        else if (pushEvent[0] === "updateNotifications"){
            loadNotifications();
            //eventBus.dispatch(pushEvent[0], {  });
        }
        else if (pushEvent[0] === "deleteMessage"){
            //console.log("edata", pushEvent[1])
            let cvals = pushEvent[1].split("_")
            if (localStorage.getItem("cSessionId") === cvals[0]) {
                eventBus.dispatch(pushEvent[0], {messageId: cvals[1] });
            }
        }
        /*
        else if (pushEvent[0] === "updateUserMenu"){
            eventBus.dispatch(pushEvent[0], {  });
        } else if (pushEvent[0] === "updateNotifications"){
            eventBus.dispatch(pushEvent[0], {  });
        }
        */
    }



    async componentDidMount() {
        this.setState({ readError: null });
        try {
            db.ref("/updStream/" + localStorage.getItem("uid")).on("value", snapshot => {
                let messages = [];
                //console.log("receiving user update")
                if (snapshot.val()){
                    //console.log(snapshot.val())
                    messages.push(snapshot.val());
                    messages.forEach((message) =>{ this.sendEvent(message) })
                    this.setState({ messages });
                }
            });

        } catch (error) {
            this.setState({ readError: error.message });
        }
    }
    purgeAlert = () => {
        console.log("in purge")
        //setAlertOpen(false);
        this.setState({ alertOpen: false });
        //setAlertObject({ open: false, type: "info", message: "", horizontal: "", vertical: "" })
        this.setState({ alertObject: { open: false, type: "info", message: "", horizontal: "", vertical: "" } });
    }

    handleChange(event) {}

    render() { return ( <><span style={{display: "none"}} />
        <BackdropAppear loading={this.state.loading || false} />
        <AlertAppear open={this.state.alertOpen} alertObj={this.state.alertObject} purgeAlert={this.purgeAlert}/>
        </>
        ); }
}
