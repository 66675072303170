
export async function micDetected() {
    return navigator.mediaDevices.enumerateDevices()
        .then(devices => {
            let hasAudio = false;
            devices.forEach(device => {

                if (device.kind === "audioinput") {
                    hasAudio = true;
                }
            });

            if (hasAudio) {
                return micAccessApproved().then(audioApproved => {
                return audioApproved;
            }) } else {
                return false;
            }

        })
        .catch(error => {
            return false;
        });
}


export async function micAccessApproved() {
    return navigator.permissions.query({name: 'microphone'}).then(function (permissionStatus) {
        //console.log("permissionStatus", permissionStatus);
        return (permissionStatus.state === "granted" || permissionStatus.state === "prompt");
    })
}

export function stopActiveTracks() {
    navigator.mediaDevices.getUserMedia({audio: true}).then(stream => {
        stream.getTracks().forEach(track => track.stop())
    })
}

export async function playSound(controlElement, audioUrl) {
    //console.log("playSound", audioUrl, controlElement)
    try {
        if (audioUrl && controlElement) {
            controlElement.src = audioUrl;
            controlElement.muted = false;
            controlElement.autoPlay = true;
            controlElement.volume = 1;
            controlElement.play().catch(error => {});
        }
    } catch (error) {
        console.log("audio did not play", error)
    }
}



