import axios from "axios";



export function req_get_project_interface(id, sessionId, body) {
  return req('GET', process.env.REACT_APP_CHAT_API + "/project/interface/" + id + "/" + sessionId + "?lang=en",{}, "");
}

export function req_get_history(session) {
  return req('GET', process.env.REACT_APP_CHAT_API + "/session/bySessionKey/" + session + "?token=" + localStorage.getItem("rtoken"));
}

export function req_create_session_id(language, name) {
  return req('GET', process.env.REACT_APP_CHAT_API + "/session/create/" + language + "/" + name + "?token=" + localStorage.getItem("rtoken"), {});
}

export function req_quick_session_id(language, name) {
  return req('GET', process.env.REACT_APP_CHAT_API + "/session/create_quick/" + language + "/" + name + "?token=" + localStorage.getItem("rtoken"), {});
}

export function req_shared_session_id(language, translateLanguage, name, guestName) {
  return req('GET', process.env.REACT_APP_CHAT_API + "/session/create_shared/" + language + "/" + translateLanguage + "/" + name + "/" + guestName + "?token=" + localStorage.getItem("rtoken"), {});
}

export function req_personal_session_id(language, translateLanguage, name) {
  return req('GET', process.env.REACT_APP_CHAT_API + "/session/create_personal/" + language + "/" + translateLanguage + "/" + name + "?token=" + localStorage.getItem("rtoken"), {});
}

export function req_join_session(sessionId, language) {
  return req('GET', process.env.REACT_APP_CHAT_API + "/session/join/" + sessionId + "/" + language + "?token=" + localStorage.getItem("rtoken"), {});
}

export function req_update_language(sessionId, language, language2 = null) {
  let l2 = "";
  if (language2) {
    l2 = "/" + language2;
  }
  return req('GET', process.env.REACT_APP_CHAT_API + "/session/update_shared_language/" + sessionId + "/" + language + l2 + "?token=" + localStorage.getItem("rtoken"), {});
}

export function req_delete_session(sessionId) {
  return req('DELETE', process.env.REACT_APP_CHAT_API + "/session/" + sessionId + "?token=" + localStorage.getItem("rtoken"), {});
}

export function req_get_all_sessions() {
  return req('GET', process.env.REACT_APP_CHAT_API + "/session/sessions?token=" + localStorage.getItem("rtoken"), {});
}

export function req_get_session(sessionId) {
  console.log("get Session", sessionId)
  return req('GET', process.env.REACT_APP_CHAT_API + "/session/active/" + sessionId + "?token=" + localStorage.getItem("rtoken"), {});
}

export function req_login_refresh(token, body) {
  return req('POST', process.env.REACT_APP_USER_API + "/user_refresh?token=" + token, body);
}

export function req_reset_password(email) {
  return req('POST', process.env.REACT_APP_USER_API + "/reset_password", {
    "email": email
  });
}

export function req_put_session_notification(body) {
  return req('PUT', process.env.REACT_APP_CHAT_API + "/session/process?token=" + localStorage.getItem("rtoken"), body);
}

export function req_get_notifications() {
  return req('GET', process.env.REACT_APP_USER_API + "/notification?token=" + localStorage.getItem("rtoken"), {});
}

export function req_get_updView() {
  return req('GET', process.env.REACT_APP_USER_API + "/notification/updview?token=" + localStorage.getItem("rtoken"), {});
}

export function req_put_delete_message(body) {
  return req('PUT', process.env.REACT_APP_CHAT_API + "/session/message_delete?token=" + localStorage.getItem("rtoken"), body);
}

export function req_put_message_feedback(body) {
  return req('PUT', process.env.REACT_APP_CHAT_API + "/session/message_feedback?token=" + localStorage.getItem("rtoken"), body);
}

export function req_put_update_session_name(body, sessionId) {
  console.log(process.env.REACT_APP_CHAT_API + "/session/update_name/"+sessionId+"?token=" + localStorage.getItem("rtoken"))
  return req('PUT', process.env.REACT_APP_CHAT_API + "/session/update_name/"+sessionId+"?token=" + localStorage.getItem("rtoken"), body);
}

export function req_user_register(email, password, displayName, handle, docsChecked, language) {
  return req('POST', process.env.REACT_APP_USER_API + "/register", {
    email: email,
    password: password,
    displayName: displayName,
    handle: handle,
    language: language,
    docsChecked: docsChecked,
    accountName: "Acct: " + displayName
  });
}

export function req_get_verify_email(pid, verify) {
  return req('GET', process.env.REACT_APP_USER_API + "/verify_email?uid=" + pid + "&verify=" + verify);
}

export function req_get_verify_otp(uid, verify) {
  return req('GET', process.env.REACT_APP_USER_API + "/verify_otp?uid=" + uid + "&verify=" + verify);
}

export function req_user_account_get(objId) {
  return req('GET', process.env.REACT_APP_USER_API + "/user/"+objId+"/account?token=" + localStorage.getItem("rtoken"));
}

export function req_user_settings_put(objId, body) {
  return req('PUT', process.env.REACT_APP_USER_API + "/user/"+objId+"/settings?token=" + localStorage.getItem("rtoken"), body);
}

export function req_get_handle_lookup(value) {
  return req('GET', process.env.REACT_APP_USER_API + "/handle_lookup?handle=" + value);
}

export function req_get_display_name_lookup(value) {
  return req('GET', process.env.REACT_APP_USER_API + "/display_name_lookup?displayname=" + value + "&token=" + localStorage.getItem("rtoken"));
}

export function req_post_display_name(body) {
  return req('POST', process.env.REACT_APP_USER_API + "/user/display_name?token=" + localStorage.getItem("rtoken"), body);
}

export function req_delete_avatar() {
  return req('DELETE', process.env.REACT_APP_USER_API + "/user/avatar?token=" + localStorage.getItem("rtoken"), {});
}

async function req(verb, url, body, acct= null){

  //let sessionData = session.getJsSession(botId) || "";
  //let botId = localStorage.getItem("agent");
  let botId = "";
  let sessionId = "";
  if (localStorage.getItem("cSessionId") && localStorage.getItem("cSessionId") !== "undefined") {
    sessionId = localStorage.getItem("cSessionId");
  }

  let ownedAccount = localStorage.getItem("ownedAccount") || localStorage.getItem("selectedAccount");

  const headers = { 'c-acct': ownedAccount, 'agent': botId, 'session': sessionId };

  if (verb === "POST") {
    return await axios.post(url, body, {headers: headers})
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  } else if (verb === "PUT") {
    return await axios.put(url, body, {headers: headers})
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          return error;
        });
  } else if (verb === "GET") {
    return await axios.get(url, {headers: headers})
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  } else if (verb === "DELETE") {
    return await axios.delete(url, {headers: headers})
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  }
}
