import React, {useEffect, useState} from "react";
import {TextField} from "@mui/material";
import {
    getListeningState,
    getOtherInvokerState, setListeningState,
    setOtherInvokerState,
    setSuppressRestartState
} from "../../helpers/session";
import HearingIcon from "@mui/icons-material/Hearing";
import MicIcon from "@mui/icons-material/Mic";
import SendIcon from "@mui/icons-material/Send";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import eventBus from "../../helpers/eventBus";
import Avatar from "@mui/material/Avatar";
import {CircleFlag} from "react-circle-flags";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import {chatTransFlagDisplay} from "../../helpers/chat";

export default function ChatSubmissionContainer (props) {


    const [language, setLanguage] = useState("")
    const [otherLanguage, setOtherLanguage] = useState("")
    const [chatDetailObj, setChatDetailObj] = useState({})
    const [micContinuous, setMicContinuous] = useState(localStorage.getItem("micContinuous") === "true")
    const [listeningState, setListeningState] = useState(false)
    const [entryText, setEntryText] = useState("")
    const [otherEntryText, setOtherEntryText] = useState("")

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);



    useEffect(() => {

        setLanguage("")
        setChatDetailObj(props.chatDetailObj || {})

        console.log("before updateSubmissionContainer")

        eventBus.on("updateSubmissionContainer", (uObj) => {
            console.log("updateSubmissionContainer")
            updateSessionData(uObj);
        });
        eventBus.remove("updateSubmissionContainer");

        eventBus.on("updateListeningState", (uObj) => {

            setListeningState(uObj.isListening);
        });
        eventBus.remove("updateListeningState");

        eventBus.on("clearEntryText", (uObj) => {
            clearEntryText();
        });
        eventBus.remove("clearEntryText");


        //this.updateSessionData();

    }, [props.chatDetailObj])

    const updateSessionData = (sessionObj) => {

        let session = localStorage.getItem("cSession") ? JSON.parse(localStorage.getItem("cSession")) : {};

        setChatDetailObj({...session})
        setMicContinuous(localStorage.getItem("micContinuous") === "true")

        if (session.id === localStorage.getItem("cSessionId")) {
            session.sessionMembers.forEach((member) => {
                if (member.userId === localStorage.getItem("uid")){
                    setLanguage(member.language)
                    if (session.sessionType === "shared" || session.sessionType === "translate")
                    {
                        session.languages.forEach((lang) => {
                            if (lang !== member.language) {
                                setOtherLanguage(lang);
                            }
                        })
                    }
                }
            })
        }
    }

    useEffect(() => {
        //console.log("Change in child detected, rendering new data", props.entryText, props.otherEntryText);
        setEntryText(props.entryText);
        setOtherEntryText(props.otherEntryText);
    }, [props.entryText, props.otherEntryText]);


    const clearEntryText = () => {

        setEntryText("");
        setOtherEntryText("");
    }


    let mainLanguage = language || "en-US";
    return (
        <div className={"submissionsContainer"} style={{
            marginLeft: chatDetailObj && chatDetailObj.hasOwnProperty("sessionType") && chatDetailObj.sessionType === "shared" ? "0px" : 0,
            gap: chatDetailObj && chatDetailObj.hasOwnProperty("sessionType") && chatDetailObj.sessionType === "shared" ? "10px" : 0

        }}>

            <div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center", position: "relative", top: "-18px"}}>
            <div style={{border: "1px solid #ccc", borderRadius: "10px", fontSize: ".7rem",
                textAlign: "center", paddingLeft: 10, paddingRight: 10, paddingTop: 2, paddingBottom: 2,
                borderBottomWidth: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, zIndex: 10000, background: "#fff"

            }}>
                {chatTransFlagDisplay(mainLanguage, mainLanguage, false, false, true)}
            </div>
            <div className={"submissionSubcontainer"} style={{
                width: "100%",
                display: "flex",
                flexDirection: chatDetailObj.sessionType === "shared" && screenWidth < 500 ? "column" : "row"
            }}>

                <TextField value={entryText}
                           variant="filled"
                           key="t2"
                           rows={3}
                           fullWidth
                           disabled={listeningState}
                           onChange={(e) => {
                               console.log("text", e.target.value);
                               props.setQuery(e)
                           }}
                           multiline
                           inputProps={{
                               maxLength: 300,
                               disableUnderline: true,
                           }}
                           style={{
                               marginRight: "5px",
                               borderRadius: "10px",
                           }}
                           sx={{
                               '.MuiInputBase-input': {fontSize: '1.1rem'},

                               "& .MuiFilledInput-root": {
                                   backgroundColor: listeningState ? '#fafafa' : "#fff",
                                   border: "1px solid #ccc",
                                   borderRadius: "10px",
                                   "&::before": {
                                       borderRadius: "10px",
                                       borderBottom: "none",
                                   },
                                   "&::after": {
                                       borderRadius: "10px",
                                       borderBottom: "none",
                                   },
                               },
                           }}

                           onKeyDown={(e) => {
                               props.onEnterPress(e)
                           }}>
                </TextField>


                <div style={{
                    display: "flex",
                    flexDirection: chatDetailObj.sessionType === "shared" && screenWidth < 500 ? "row" : "column",
                    justifyContent: chatDetailObj.sessionType === "shared" && screenWidth < 500 ? "flex-end" : "flex-end",
                    paddingBottom: "10px",
                }}>


                    <div style={{textAlign: "left", display: "flex", flexWrap: "none", marginLeft: 10}}>

                        <Avatar
                            sx={{bgcolor: "#777", height: '32px', width: '32px'}}
                            style={{
                                marginLeft: "5px",
                                right: "8px",
                                color: "#fff"
                            }}
                            onClick={(e) => {
                                props.sendMessage(e)
                            }}
                        ><SendIcon/></Avatar>

                        <Avatar sx={{
                            bgcolor: listeningState && !getOtherInvokerState() ? "#80b33c" : "#777",
                            height: '32px',
                            width: '32px'
                        }}
                                onClick={listeningState ?
                                    () => {
                                        setSuppressRestartState(true);
                                        setListeningState(false);
                                        props.updRecordingState(false)
                                        props.turnOffMic();

                                    } : () => {

                                        setOtherInvokerState(false);
                                        props.turnOffMic();
                                        setListeningState(true);

                                        props.updRecordingState(true)
                                        if (localStorage.getItem("micContinuous") === "true") {
                                            setSuppressRestartState(false);
                                        } else {
                                            setSuppressRestartState(true);
                                        }

                                        props.setSpeakingLanguage(language);
                                        props.turnOnMic(language);

                                    }}>
                            <KeyboardVoiceIcon/>
                        </Avatar>

                    </div>
                </div>
            </div>
            </div>

            {chatDetailObj && chatDetailObj.hasOwnProperty("sessionType") && chatDetailObj.sessionType === "shared" ?
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                    position: "relative",
                    top: "-18px"
                }}>
                    <div style={{
                        border: "1px solid #ccc",
                        borderRadius: "10px",
                        fontSize: ".7rem",
                        textAlign: "center",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 2,
                        paddingBottom: 2,
                        borderBottomWidth: 0,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        zIndex: 10000,
                        background: "#fff"

                    }}>
                        {chatTransFlagDisplay(otherLanguage, otherLanguage, false, false, true)}</div>
                    <div className={"submissionSubcontainer"} style={{
                        width: "100%",
                        marginLeft: 10,
                        display: "flex",
                        flexDirection: screenWidth < 500 ? "column" : "row"
                    }}>

                        <TextField value={otherEntryText}
                                   key="t1"
                                   fullWidth
                                   variant="filled"
                                   rows={3}
                                   disabled={listeningState}
                                   onChange={(e) => {
                                       props.setOtherQuery(e)
                                   }}
                                   multiline
                                   inputProps={{
                                       maxLength: 300,
                                       disableUnderline: true,
                                   }}
                                   style={{
                                       marginRight: "5px",
                                       borderRadius: "10px",
                                   }}
                                   sx={{
                                       '.MuiInputBase-input': {fontSize: '1.1rem'},

                                       "& .MuiFilledInput-root": {
                                           backgroundColor: listeningState ? '#fafafa' : "#fff",
                                           border: "1px solid #ccc",
                                           borderRadius: "10px",
                                           "&::before": {
                                               borderRadius: "10px",
                                               borderBottom: "none",
                                           },
                                           "&::after": {
                                               borderRadius: "10px",
                                               borderBottom: "none",
                                           },
                                       },
                                   }}>
                            onKeyDown={(e) => {
                            props.onOtherEnterPress(e)
                        }}>
                        </TextField>

                        <div style={{
                            display: "flex",
                            flexDirection: screenWidth < 500 ? "row" : "column",
                            justifyContent: screenWidth < 500 ? "flex-end" : "flex-end",
                            paddingBottom: "10px"
                        }}>

                            <div style={{textAlign: "left", display: "flex", flexWrap: "none", marginLeft: 10}}>

                                <Avatar
                                    sx={{bgcolor: "#777", height: '32px', width: '32px'}}
                                    style={{
                                        marginLeft: "5px",
                                        right: "8px",
                                        color: "#fff"
                                    }}
                                    onClick={(e) => {
                                        props.sendOtherMessage(e)
                                    }}
                                ><SendIcon/></Avatar>

                                <Avatar sx={{
                                    bgcolor: listeningState && getOtherInvokerState() ? "#80b33c" : "#777",
                                    height: '32px',
                                    width: '32px'
                                }}
                                        onClick={getListeningState() ?
                                            () => {
                                                setSuppressRestartState(true);
                                                props.updRecordingState(false)
                                                setListeningState(false)
                                                setOtherInvokerState(false);
                                                props.turnOffMic();
                                            } : () => {

                                                setListeningState(true);
                                                if (localStorage.getItem("micContinuous") === "true") {
                                                    setSuppressRestartState(false);
                                                } else {
                                                    setSuppressRestartState(true);
                                                }
                                                setListeningState(true)
                                                setOtherInvokerState(true);
                                                props.updRecordingState(true);
                                                props.setSpeakingLanguage(otherLanguage);
                                                props.turnOffMic();
                                                props.turnOnMic(otherLanguage);

                                            }
                                        }
                                >
                                    <KeyboardVoiceIcon/>
                                </Avatar>


                            </div>
                        </div>
                    </div>
                </div>
                    : null
                    }

                    {chatDetailObj && chatDetailObj.hasOwnProperty("sessionType") && chatDetailObj.sessionType === "translatex" ?
                        <div className={"submissionSubcontainer"} style={{
                            width: "100%",
                            marginLeft: 10,
                            display: "flex",
                            flexDirection: screenWidth < 500 ? "column" : "row"
                        }}>


                            <TextField value={otherEntryText}
                                       key="t1"
                                       fullWidth
                                       rows={3}
                                       disabled={true}
                                       onChange={(e) => {
                                           props.setOtherQuery(e)
                                       }}

                                       multiline
                                       inputProps={{
                                           maxLength: 300,
                                       }}
                                       sx={{
                                           '.MuiInputBase-input': {fontSize: '1.1rem'},
                                           '& .MuiOutlinedInput-notchedOutline': {
                                               backgroundColor: '#fafafa'
                                           }
                                       }}
                                       style={{
                                           borderColor: "#eee",
                                           marginRight: "5px"

                                       }}>
                                onKeyDown={(e) => {
                                props.onOtherEnterPress(e)
                            }}>
                            </TextField>

                            <div style={{
                                display: "flex",
                                flexDirection: screenWidth < 500 ? "row" : "column",
                                justifyContent: screenWidth < 500 ? "flex-end" : "inherit",
                                paddingBottom: "10px"
                            }}>
                                <div style={{
                                    fontSize: ".7rem",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 3
                                }}>
                                    {chatTransFlagDisplay(otherLanguage, otherLanguage, false, false, true)}
                                </div>

                            </div>
                        </div>
                        : null
                    }
                </div>
                );

            }

