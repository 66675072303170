import React, { useState, useEffect, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import ForumIcon from '@mui/icons-material/Forum';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {req_get_all_sessions, req_join_session, req_login_refresh, req_reset_password} from "../../helpers/requests";
import {
    createChatSession, createChatSessionAsync,
    createChatSessionObject,
    createChatSessionUser,
    createSelectedAccount
} from "../../helpers/session";
import LanguageSelector from "../embeddedSelectors/languageSelector";
import {content} from "../../globals/content";
import eventBus from "../../helpers/eventBus";
import {auth} from "../../helpers/firebase";
import {setLocalStorageVals} from "../../helpers/settings";

import { signInAnonymously, GoogleAuthProvider } from "firebase/auth";
import BackdropAppear from "../staticDisplays/backdropAppear";
import {useNavigate} from "react-router-dom";

const theme = createTheme();

export default function QuickConnectInvitation() {
    const navigate = useNavigate();
    const [language, setLanguage] = React.useState("");
    const [chatCode, setChatCode] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const provider = new GoogleAuthProvider();
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log("Invitecode", data.get('inviteCode'))

        if (chatCode){

            // create anonymous session

            setLoading( true );
            signInAnonymously(auth)
                .then((userCredential) => {
                    // Successfully signed in anonymously
                    const user = auth.currentUser;
                    console.log("Anonymous user:", user);
                    const token = userCredential.user.stsTokenManager.refreshToken;
                    console.log("Anonymous user token:", token);

                    let body = {
                        email: "",
                        emailVerified: false,
                        isSocial: true,
                        socialType: "anonymous",
                        isAnonymous: true,
                        inviteId: chatCode
                    }


                    req_login_refresh(token, body).then(function (result) {

                        if (result.status === 200) {
                            setLocalStorageVals(result);
                            localStorage.setItem("wstt", "68245")

                            req_join_session(data.get('inviteCode'), language).then((response) => {
                                console.log("req_join_session response", response);
                                if (response.status === 200) {
                                    console.log("QC Join", response.data)
                                    createChatSessionAsync(response.data.newSession).then((result) => {
                                       console.log("createChatSession response", result);
                                    });
                                    createChatSessionObject(response.data.newSession);
                                    createChatSessionUser(response.data.userId);
                                    createSelectedAccount(response.data.sessionAccount)
                                    eventBus.dispatch("updateUserWidgets", {isAnonymous: true});
                                    navigate("/c/" + response.data.newSession ,{ replace: true});
                                    setLoading( false );

                                } else {
                                    alert("Invalid session code", data.get('inviteCode'));
                                    setLoading( false );
                                }
                            })


                            //setLoading(false)
                        } else {
                            //setLoading(false);
                            //setShowSocialError(true);
                            //setTimeout(() => setShowSocialError(false), 5000);
                            setLoading( false );
                        }

                    })

                })
                .catch((error) => {
                    // Handle errors here
                    console.error("Error signing in anonymously:", error);
                });
        }
    };


    useEffect(() => {

        let path = window.location.pathname;
        if (path.split("/").length > 1) {
            setChatCode(path.split("/")[2])
        }
        setLanguage(localStorage.getItem("siteLanguage") || "en-US");

    }, []);



    return (
        <ThemeProvider theme={theme}>
            <BackdropAppear loading={loading || false} />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: '#FF2F00' }}>
                        <ForumIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {content.chatInviteScreenContent[localStorage.getItem("siteLanguage") || "en-US"].title}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="inviteCode"
                            value={chatCode}
                            label={content.chatInviteScreenContent[localStorage.getItem("siteLanguage") || "en-US"].chatCodeField}
                            name="inviteCode"
                            autoFocus

                        />
                        <div style={{textAlign: "center", margin: "10px auto", width: "300px"}}>
                            <LanguageSelector language={language} setLanguage={setLanguage}
                                              label={content.chatInviteScreenContent[localStorage.getItem("siteLanguage") || "en-US"].languageField}
                                              mutliSelect={false}/>
                        </div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 1, mb: 2, backgroundColor: "#424242", textTransform: "none"}}
                        >
                            {content.chatInviteScreenContent[localStorage.getItem("siteLanguage") || "en-US"].joinButton}
                        </Button>

                    </Box>
                </Box>

            </Container>
        </ThemeProvider>
    );
}
