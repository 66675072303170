import React, {Component, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {content} from "../../globals/content";
import {AvatarGroup, Tooltip} from "@mui/material";
import {req_get_all_sessions} from "../../helpers/requests";
import eventBus from "../../helpers/eventBus";
import CircularProgress from "@mui/material/CircularProgress";
import {createChatSession, sessionReset} from "../../helpers/session";
import ChatControl from "../pages/chatControl";

export default function SessionSelector(props) {


    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const navigate = useNavigate();


    useEffect(() => {
        loadSessions()
    }, []);


    const loadSessions = () => {
        //let self = this;
        let sessions = []


        let allSessions = JSON.parse(localStorage.getItem("chatSessions")) || [];
        if (props.sessionType) {
            allSessions.forEach((session) => {
                if (session.sessionType === props.sessionType) {
                    sessions.push(session)
                }
            })
        } else {
            sessions = allSessions;
        }
        setRows(sessions)
        setLoading(false)



        /*
        req_get_all_sessions()
            .then(function (result) {
                if (result.status === 200) {
                    localStorage.setItem("chatSessions", JSON.stringify(result.data.sessions));
                    console.log("session")
                    if (self.props.sessionType) {
                        result.data.sessions.forEach((session) => {
                            if (session.sessionType === self.props.sessionType) {
                                sessions.push(session)
                            }
                        })
                    } else {
                        sessions = result;
                    }
                }
                self.setState({rows: sessions, loading: false})
            })
        */
    }


    const formatMembers = (members) => {
        let membersString = ""
        members.forEach((member) => {
            membersString += member + ", "
        })
        return membersString
    }

    const joinSession = (sessionId) => {
        console.log("join3")
        sessionReset(sessionId);

    }









    /*
    joinSession = (row) => {
        let lang = "";
        row.sessionMembers.forEach((member) => {
            if (member.userId === localStorage.getItem("uid")){
                console.log("found")
                lang = member.language;
            }
        })

        createChatSession(row.id);
        this.props.joinSession(row.id, lang, row)
    }

     */


    return (
        <div style={{ padding: "1px"
        }}>
            {rows.length > 0 ?
            <TableContainer component={Paper}>
                <Table sx={{ }} >
                    <TableHead>
                        <TableRow key={"tablehead"}>
                            <TableCell component="th">{content.sessionsTableContent[localStorage.getItem("siteLanguage") || "en-US"].nameHeader}</TableCell>
                            <TableCell component="th" align="left">{content.sessionsTableContent[localStorage.getItem("siteLanguage") || "en-US"].memberHeader}</TableCell>
                            <TableCell component="th" align="left">{content.sessionsTableContent[localStorage.getItem("siteLanguage") || "en-US"].languageHeader}</TableCell>

                            <TableCell component="th"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (

                                <TableRow key={row.id}>
                                    <TableCell align="left" style={{fontWeight: "bold"}}><Link style={{cursor: "pointer"}} onClick={() => {

                                        navigate("/c/" + row.id, { replace: true})
                                    }}>{row.name}</Link></TableCell>
                                    <TableCell align="left">
                                        <AvatarGroup sx={{
                                            '& .MuiAvatar-root': {
                                                boxSizing: "border-box",
                                                width: 24,
                                                height: 24,
                                                fontSize: ".7rem",
                                            },
                                        }}
                                                     max={3}>
                                        {row.sessionMembers.map((member) => {
                                            return (<Tooltip key={member.userId} title={member.displayName}><Avatar
                                                key={member.userId}
                                                sx={{

                                                    bgcolor: row.hasOwnProperty("userProps")
                                                    && row.userProps.hasOwnProperty(member.userId)
                                                    && row.userProps[member.userId].hasOwnProperty("avatarColor")
                                                        ? row.userProps[member.userId].avatarColor : "#eee",

                                                    width: 24,
                                                    height: 24,
                                                    fontSize: ".7rem",
                                                    color: "#fff",
                                                    textAlign: "center",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    display: "flex"

                                                }}

                                                src={row.hasOwnProperty("userProps")
                                                && row.userProps.hasOwnProperty(member.userId)
                                                && row.userProps[member.userId].hasOwnProperty("avatarUrl")
                                                    ?
                                                    process.env.REACT_APP_FB_STORAGE_URL + row.userProps[member.userId].avatarUrl.replace("/", "%2F") + "?alt=media"
                                                    : null}
                                                alt={member.displayName}
                                            >{member.displayName[0]}</Avatar></Tooltip>)
                                        })
                                        }
                                        </AvatarGroup>
                                    </TableCell>
                                    <TableCell align="left">{row.languages.join(", ")}</TableCell>

                                    <TableCell align="left">

                                        <Link  style={{cursor: "pointer"}} onClick={() => {
                                            navigate("/c/" + row.id, { replace: true})
                                        }}>
                                        <OpenInNewIcon style={{fontSize: "1.1rem"}} />
                                    </Link></TableCell>

                                </TableRow>

                        ))
                        }
                    </TableBody>

                </Table>
            </TableContainer>
            : <div style={{textAlign: "center", border: "1px solid #ccc", borderRadius: 5, fontWeight: "bold", margin: 5, padding: 5}}>
                    {!loading && rows.length === 0 ?
                        <p>{content.sessionsTableContent[localStorage.getItem("siteLanguage") || "en-US"].noSessions}</p> :
                        <CircularProgress size={20}/>
                    }
            </div>}


        </div>
    )

}


/*
<TableCell align="left"><Link  style={{cursor: "pointer"}} onClick={() => {joinSession(row)}}>
                                        <OpenInNewIcon style={{fontSize: "1.1rem"}} />
                                    </Link></TableCell>
 */