import {useParams, useNavigate, useLocation} from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import React, {useEffect} from "react";
//import Chat from "./chat";
import {createChatSessionId} from "../../helpers/session";
import ChatUpd from "./chatUpd";
import Button from "@mui/material/Button";


export default function ChatControl(props) {

    const [conversationType, setConversationType] = React.useState("");
    const [language, setLanguage] = React.useState("");
    const [chatSessionId, setChatSessionId] = React.useState("");
    const [sessionSpur, setSessionSpur] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [sessionObj, setSessionObj] = React.useState({});

    const {chatId} = useParams();
    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        loadSession();
    }, [location])


    const loadSession = () => {
        clearSession()
        setLoaded(false);
        createChatSessionId(chatId).then((response) => {

            setChatSessionId(response.id);
            setConversationType(response.conversationType);
            setLoaded(true);
            setSessionObj(response);
        })
    }

    const clearSession = () => {
        setChatSessionId("");
        setConversationType("");
        setLanguage("");
        setSessionObj({...{}})
    }




    return (
        <div>

            {!loaded && <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                flex: 1,
                height: 300
            }}>
                <CircularProgress size="30px"/>
            </div>}

            {(loaded && sessionObj.id && !sessionObj.deleted) || loaded && chatId === "base" ?
                <ChatUpd selLang={language}
                         conversationType={conversationType}
                         chatSessionId={chatSessionId}
                         clearSession={clearSession}
                      updSession={sessionSpur}
                      sessionObj={sessionObj}
                />
            : null
            }

            {(loaded && sessionObj.deleted || !sessionObj.id) && chatId !== "base"  ?
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    flex: 1,
                    height: 300
                }}>

                    <div>Oops! This session has been deleted or you do not have permission to access it.</div>
                    <Button onClick={() =>{
                        navigate("/c/base" ,{ replace: true});
                    }} variant={"contained"} >go to dashboard</Button>
                </div> :
                null
            }

        </div>

    )

}