import React, {Component, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,

    DialogTitle,
    IconButton,
    TextField
} from "@mui/material";
import LanguageSelector from "../embeddedSelectors/languageSelector";
import QRCode from "react-qr-code";
import {
    req_create_session_id,
    req_quick_session_id,
    req_personal_session_id,
    req_shared_session_id
} from "../../helpers/requests";
import {
    sessionReset
} from "../../helpers/session";
import eventBus from "../../helpers/eventBus";
import {content} from "../../globals/content";
import {ExplanationCard} from "../widgets/explanationCard";
import CopyAllIcon from "@mui/icons-material/CopyAll";

export default function CreateChat (props) {


    const [open, setOpen] = React.useState(true);
    const [conversationType, setConversationType] = React.useState("");
    const [sessionCode, setSessionCode] = React.useState("");
    const [language, setLanguage] = React.useState("");
    const [peerLanguage, setPeerLanguage] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [chatSessionId, setChatSessionId] = React.useState("");
    const [name, setName] = React.useState("");
    const [guestName, setGuestName] = React.useState("Guest");
    const [chatNameError, setChatNameError] = React.useState(false);
    const [guestNameError, setGuestNameError] = React.useState(false);
    const [inviteId, setInviteId] = React.useState("");
    const [showQrCode, setShowQrCode] = React.useState(false);
    const [typeProperties, setTypeProperties] = React.useState({
        conversation: {
           icon: "forum",
           color: "#cd5c5c",
           titleProp: "selectChatTypeGroupChat"
        },
        translate: {
           icon: "translate",
           color: "#7772b8",
           titleProp: "selectChatTypeTranslationChat"
        },
        shared: {
           icon: "account-multiple",
           color: "#008080",
           titleProp: "selectChatTypeSharedScreenChat"
        },
        quickConnect: {
           icon: "qrcode",
           color: "#de3163",
           titleProp: "selectChatTypeQuickConnectChat"
        }
    });

    const navigate = useNavigate();


    const handleClose = () => {
        setOpen(false);
        props.close()
    }

    const handleCopy = () => {
        console.log("copy")
        if (conversationType === "quickConnect") {
            navigator.clipboard.writeText(process.env.REACT_APP_QC_BASE_URL + inviteId);
        } else {
            navigator.clipboard.writeText(process.env.REACT_APP_INV_BASE_URL + inviteId);
        }
    }

    useEffect(() => {

        console.log("loading create")
        setLanguage(localStorage.getItem("siteLanguage") || "en-US");
        setPeerLanguage(localStorage.getItem("siteLanguage") || "en-US");
        if (props.preselect && props.preselect !== "") {
            console.log("preselect", props.preselect);
            setConversationType(props.preselect);
        } else {
            console.log("no preselect", props.preselect);
            setConversationType("");
        }

    },[])

    const setConversationInvoke = (ctype) => {
        setConversationType(ctype);
    }

    /*
    const setLanguage = (lang) => {
        setLanguage(lang);
    }
    const setPeerLanguage = (lang) => {
        setPeerLanguage(lang);
    }

     */

    const clearChatData = () => {
        setName("");
        setGuestName("Guest");
        setLanguage("en-US");
        setPeerLanguage("en-US");
        setShowQrCode(false);
        setConversationType("");

    }

    const updateSession = () => {

        let sessionObj = JSON.parse(localStorage.getItem("cSession"));
        console.log("UPDATE SESSION", sessionObj);

        navigate("/c/" + sessionObj.id ,{ replace: true});
        //eventBus.dispatch("rebaseChat", {sessionObj: sessionObj});
    }

    const createSession = (vConversationType, vSessionCode, vLanguage) => {

        let self = this;
        setChatNameError(false);
        setGuestNameError(false);

        if (!name) {
            setChatNameError(true);
            return;
        }

        if ((conversationType === "shared") && !guestName) {
            setGuestNameError(true);
            return;
        }

        eventBus.dispatch("showLoader", {showLoader: true})

        let sessionRequest = null;
        if (conversationType === "conversation") {
            sessionRequest = req_create_session_id(language, name)
        } else if (conversationType === "shared") {
            sessionRequest = req_shared_session_id(language, peerLanguage, name, guestName)
        } else if (conversationType === "translate") {
            sessionRequest = req_personal_session_id(language, peerLanguage, name)
        } else {
            sessionRequest = req_quick_session_id(language, name)
        }
        console.log(sessionRequest)


        sessionRequest
            .then((response) => {
                console.log("NEW RESPONSE", response.data)

                setInviteId(response.data.inviteId);


                if (conversationType === "translate" || conversationType === "shared") {
                    console.log("Open directly", response.data.newSession)
                    navigate("/c/" + response.data.newSession ,{ replace: true});

                } else {
                    sessionReset(response.data.newSession, false);

                    setShowQrCode(true);
                }

                eventBus.dispatch("updateSessions", {}); // ?

            })
            .catch((error) => console.error("Session creation failed:", error))
            .finally(() => eventBus.dispatch("showLoader", {showLoader: false}));

    }




    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            style={{minWidth: 370}}
            open={props.open}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    handleClose();
                },
            }}
        >
            <DialogTitle>{content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].title}</DialogTitle>
            <DialogContent style={{padding: "10px"}}>
                {conversationType === "" ?
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        gap: 10,
                        justifyContent: "space-around"
                    }}>

                        <ExplanationCard key={"cardImp_conversation"}
                                         sessionType={"conversation"}
                                         setSessionType={setConversationInvoke}
                                         title={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].selectChatTypeGroupChat}
                                         typeProps={typeProperties.conversation}
                                         text={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].descriptionGroupChat}
                                         createButton={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].createButton}
                        />

                        <ExplanationCard key={"cardImp_translate"}
                                         sessionType={"translate"}
                                         setSessionType={setConversationInvoke}
                                         title={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].selectChatTypeTranslationChat}
                                         typeProps={typeProperties.translate}
                                         text={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].descriptionTranslationChat}
                                         createButton={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].createButton}
                        />
                        <ExplanationCard key={"cardImp_shared"}
                                         sessionType={"shared"}
                                         setSessionType={setConversationInvoke}
                                         title={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].selectChatTypeSharedScreenChat}
                                         typeProps={typeProperties.shared}
                                         text={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].descriptionSharedScreenChat}
                                         createButton={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].createButton}
                        />
                        <ExplanationCard key={"cardImp_quickConnect"}
                                         sessionType={"quickConnect"}
                                         setSessionType={setConversationInvoke}
                                         title={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].selectChatTypeQuickConnectChat}
                                         typeProps={typeProperties.quickConnect}
                                         text={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].descriptionQuickConnectChat}
                                         createButton={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].createButton}
                        />

                    </div> :


                    <div>
                        {!showQrCode ?
                    <div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                backgroundColor: "#f7f7f7",
                                justifyContent: "space-between",
                                borderWidth: 1,
                                borderColor: "#ddd",
                                padding: 10,
                                borderRadius: 5
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    fontSize: "1rem",
                                    fontWeight: "bold"
                                }}>
                                    {content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"][typeProperties[conversationType].titleProp]}
                                </div>
                                <div>
                                    <Button
                                        onClick={clearChatData}>{content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].changeButton}</Button>
                                </div>
                            </div>



                                {conversationType ?
                                    <div style={{width: "100%", margin: "10px auto 0px auto"}}>

                                        <div style={{textAlign: "center"}}>
                                            <TextField value={name}
                                                       required={true}
                                                       inputProps={{ maxLength: 18 }}
                                                       error={chatNameError}
                                                       label={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].chatNameLabel}
                                                       onChange={(e) => {

                                                           setName(e.target.value)
                                                       }} style={{width: "300px"}}
                                            >
                                            </TextField>
                                        </div>
                                    </div>
                                    : null
                                }


                                <div style={{textAlign: "center", margin: "10px auto", width: "300px"}}>
                                    <LanguageSelector language={language} setLanguage={setLanguage}
                                                      label={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].myLanguageLabel} mutliSelect={false}/>


                                    {conversationType === "shared" ?
                                        <div style={{width: "100%", margin: "10px auto 0px auto"}}>

                                            <div style={{textAlign: "center"}}>
                                                <TextField value={guestName}
                                                           required={true}
                                                           error={guestNameError}
                                                           label={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].guestNameLabel}
                                                           onChange={(e) => {
                                                               setGuestName(e.target.value)
                                                           }} style={{width: "300px"}}
                                                >
                                                </TextField>
                                            </div>
                                        </div>
                                        : null
                                    }


                                    {conversationType === "shared" || conversationType === "translate" ?
                                        <div style={{textAlign: "center", margin: "10px auto", width: "300px"}}>
                                            <LanguageSelector language={peerLanguage} setLanguage={setPeerLanguage}
                                                              label={content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].translateToLanguageLabel} mutliSelect={true} />

                                        </div>: null
                                    }
                                </div>


                    </div>: <div>
                                <div style={{fontSize: "1.2rem", fontWeight: "bold", textAlign: "center", marginBottom: 15}}>
                                    {content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].linkContentHead}
                                </div>
                                <div style={{textAlign: "center", marginBottom: 15}}>
                                    {content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].linkContentDescription}
                                </div>
                                <div style={{textAlign: "center", alignSelf: "center", marginTop: 10}}>
                                    {conversationType === "quickConnect" ?

                                        <QRCode
                                            value={process.env.REACT_APP_QC_BASE_URL + inviteId}
                                        /> :
                                        <QRCode
                                            value={process.env.REACT_APP_INV_BASE_URL + inviteId}
                                        />
                                    }
                                </div>

                                <div style={{marginTop: 20, textAlign: "center", marginBottom: 15}}>



                                    {conversationType === "quickConnect" ?

                                        <div>
                                            {process.env.REACT_APP_QC_BASE_URL + inviteId}
                                            <IconButton onClick={handleCopy}><CopyAllIcon /></IconButton>
                                        </div>

                                         :
                                        <div>
                                            {process.env.REACT_APP_INV_BASE_URL + inviteId}
                                            <IconButton onClick={handleCopy}><CopyAllIcon /></IconButton>
                                        </div>
                                    }

                                </div>
                                <div style={{textAlign: "center"}}>
                                <Button style={{
                                    margin: 5,
                                    borderRadius: 5,
                                    backgroundColor: "#333",
                                    color: "#FFF",

                                }} onClick={updateSession}

                                >
                                    {content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].linkContentButton}
                                </Button>
                                </div>

                            </div>
                        }
                </div>

                }

            </DialogContent>
            {!showQrCode ?
            <DialogActions>
                <Button variant={"outlined"} onClick={handleClose}>{content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].cancelButton}</Button>
                <Button variant={"outlined"} onClick={createSession}>{content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].createButton}</Button>
            </DialogActions>
            : null
            }
        </Dialog>
    );

}
