import {CircleFlag} from "react-circle-flags";

const translationMap = {
    "en": {
        "en": "English",
        "es": "Spanish",
        "fr": "French",
        "de": "German",
        "it": "Italian",
        "hi": "Hindi",
        "cmn": "Mandarin",
        "so": "Somali",
        "pt": "Portuguese",
        "id": "Indonesian",
        "vi": "Vietnamese",
        "ko": "Korean",
        "ja": "Japanese",
        "ar": "Arabic",
        "fil": "Filipino",
        "ne": "Nepali",
        "am": "Amharic",
        "ru": "Russian",
        "uk": "Ukrainian",
        "ps": "Pashto",
        "ht": "Haitian Creole",
    },
    "es": {
        "en": "Inglés",
        "es": "Español",
        "fr": "Francés",
        "de": "Alemán",
        "it": "Italiano",
        "hi": "Hindi",
        "cmn": "Mandarín",
        "so": "Somalí",
        "pt": "Portugués",
        "id": "Indonesio",
        "vi": "Vietnamita",
        "ko": "Coreano",
        "ja": "Japonés",
        "ar": "Árabe",
        "fil": "Filipino",
        "ne": "Nepalí",
        "am": "Amárico",
        "ru": "Ruso",
        "uk": "Ucraniano",
        "ps": "Pastún",
        "ht": "Criollo haitiano",
    },
    "fr": {
        "en": "Anglais",
        "es": "Espagnol",
        "fr": "Français",
        "de": "Allemand",
        "it": "Italien",
        "hi": "Hindi",
        "cmn": "Mandarin",
        "so": "Somali",
        "pt": "Portugais",
        "id": "Indonésien",
        "vi": "Vietnamien",
        "ko": "Coréen",
        "ja": "Japonais",
        "ar": "Arabe",
        "fil": "Philippin",
        "ne": "Népalais",
        "am": "Amharique",
        "ru": "Russe",
        "uk": "Ukrainien",
        "ps": "Pachto",
        "ht": "Créole haïtien",
    },
    "de": {
        "en": "Englisch",
        "es": "Spanisch",
        "fr": "Französisch",
        "de": "Deutsch",
        "it": "Italienisch",
        "hi": "Hindi",
        "cmn": "Mandarin",
        "so": "Somali",
        "pt": "Portugiesisch",
        "id": "Indonesisch",
        "vi": "Vietnamesisch",
        "ko": "Koreanisch",
        "ja": "Japanisch",
        "ar": "Arabisch",
        "fil": "Filipino",
        "ne": "Nepalesisch",
        "am": "Amharisch",
        "ru": "Russisch",
        "uk": "Ukrainisch",
        "ps": "Paschtu",
        "ht": "Haitianisch",
    },
    "it": {
        "en": "Inglese",
        "es": "Spagnolo",
        "fr": "Francese",
        "de": "Tedesco",
        "it": "Italiano",
        "hi": "Hindi",
        "cmn": "Mandarino",
        "so": "Somalo",
        "pt": "Portoghese",
        "id": "Indonesiano",
        "vi": "Vietnamita",
        "ko": "Coreano",
        "ja": "Giapponese",
        "ar": "Arabo",
        "fil": "Filippino",
        "ne": "Nepalese",
        "am": "Amarico",
        "ru": "Russo",
        "uk": "Ucraino",
        "ps": "Pashto",
        "ht": "Creolo haitiano",
    },
    "hi": {
        "en": "अंग्रेज़ी",
        "es": "स्पेनिश",
        "fr": "फ्रेंच",
        "de": "जर्मन",
        "it": "इतालवी",
        "hi": "हिंदी",
        "cmn": "मैंडेरिन",
        "so": "सोमाली",
        "pt": "पुर्तगाली",
        "id": "इंडोनेशियाई",
        "vi": "वियतनामी",
        "ko": "कोरियाई",
        "ja": "जापानी",
        "ar": "अरबी",
        "fil": "फिलिपिनो",
        "ne": "नेपाली",
        "am": "अम्हेरिक",
        "ru": "रूसी",
        "uk": "यूक्रेनी",
        "ps": "पश्तो",
        "ht": "हैतियन क्रियोल",
    },
    "cmn": {
        "en": "英语",
        "es": "西班牙语",
        "fr": "法语",
        "de": "德语",
        "it": "意大利语",
        "hi": "印地语",
        "cmn": "普通话",
        "so": "索马里语",
        "pt": "葡萄牙语",
        "id": "印尼语",
        "vi": "越南语",
        "ko": "韩语",
        "ja": "日语",
        "ar": "阿拉伯语",
        "fil": "菲律宾语",
        "ne": "尼泊尔语",
        "am": "阿姆哈拉语",
        "ru": "俄语",
        "uk": "乌克兰语",
        "ps": "普什图语",
        "ht": "海地克里奥尔语",
    },
    "so": {
        "en": "Ingiriis",
        "es": "Isbaanish",
        "fr": "Faransiis",
        "de": "Jarmal",
        "it": "Talyaani",
        "hi": "Hindi",
        "cmn": "Mandariin",
        "so": "Soomaali",
        "pt": "Boortaqiis",
        "id": "Indoneesian",
        "vi": "Fiyetnaam",
        "ko": "Kuuriyaan",
        "ja": "Jabaaniis",
        "ar": "Carabi",
        "fil": "Filibiino",
        "ne": "Nepaali",
        "am": "Axmaari",
        "ru": "Ruush",
        "uk": "Yukreeniyaan",
        "ps": "Pashto",
        "ht": "Kriyol Ayisyen",
    },
    "pt": {
        "en": "Inglês",
        "es": "Espanhol",
        "fr": "Francês",
        "de": "Alemão",
        "it": "Italiano",
        "hi": "Hindi",
        "cmn": "Mandarim",
        "so": "Somali",
        "pt": "Português",
        "id": "Indonésio",
        "vi": "Vietnamita",
        "ko": "Coreano",
        "ja": "Japonês",
        "ar": "Árabe",
        "fil": "Filipino",
        "ne": "Nepalês",
        "am": "Amárico",
        "ru": "Russo",
        "uk": "Ucraniano",
        "ps": "Pashto",
        "ht": "Crioulo haitiano",
    },
    "id": {
        "en": "Inggris",
        "es": "Spanyol",
        "fr": "Perancis",
        "de": "Jerman",
        "it": "Italia",
        "hi": "Hindi",
        "cmn": "Mandarin",
        "so": "Somali",
        "pt": "Portugis",
        "id": "Indonesia",
        "vi": "Vietnam",
        "ko": "Korea",
        "ja": "Jepang",
        "ar": "Arab",
        "fil": "Filipina",
        "ne": "Nepal",
        "am": "Amhar",
        "ru": "Rusia",
        "uk": "Ukraina",
        "ps": "Pashto",
        "ht": "Kreol Haiti",
    },
    "vi": {
        "en": "Tiếng Anh",
        "es": "Tiếng Tây Ban Nha",
        "fr": "Tiếng Pháp",
        "de": "Tiếng Đức",
        "it": "Tiếng Ý",
        "hi": "Tiếng Hin-ddi",
        "cmn": "Tiếng Quan-thoại",
        "so": "Tiếng Somali",
        "pt": "Tiếng Bồ Đào Nha",
        "id": "Tiếng In-đô-nê-xi-a",
        "vi": "Tiếng Việt",
        "ko": "Tiếng Hàn",
        "ja": "Tiếng Nhật",
        "ar": "Tiếng Ả Rập",
        "fil": "Tiếng Phi-lip-pin",
        "ne": "Tiếng Nepal",
        "am": "Tiếng Am-ha-ri",
        "ru": "Tiếng Nga",
        "uk": "Tiếng U-crai-na",
        "ps": "Tiếng Pashto",
        "ht": "Tiếng Haiti",
    },
    "ko": {
        "en": "영어",
        "es": "스페인어",
        "fr": "프랑스어",
        "de": "독일어",
        "it": "이탈리아어",
        "hi": "힌디어",
        "cmn": "중국어",
        "so": "소말리어",
        "pt": "포르투갈어",
        "id": "인도네시아어",
        "vi": "베트남어",
        "ko": "한국어",
        "ja": "일본어",
        "ar": "아랍어",
        "fil": "필리핀어",
        "ne": "네팔어",
        "am": "아마리어",
        "ru": "러시아어",
        "uk": "우크라이나어",
        "ps": "파슈토어",
        "ht": "아이티 크리올어",
    },
    "ja": {
        "en": "英語",
        "es": "スペイン語",
        "fr": "フランス語",
        "de": "ドイツ語",
        "it": "イタリア語",
        "hi": "ヒンディー語",
        "cmn": "中国語",
        "so": "ソマリア語",
        "pt": "ポルトガル語",
        "id": "インドネシア語",
        "vi": "ベトナム語",
        "ko": "韓国語",
        "ja": "日本語",
        "ar": "アラビア語",
        "fil": "フィリピン語",
        "ne": "ネパール語",
        "am": "アムハラ語",
        "ru": "ロシア語",
        "uk": "ウクライナ語",
        "ps": "パシュト語",
        "ht": "ハイチクレオール語",
    },
    "ar": {
        "en": "الإنجليزية",
        "es": "الإسبانية",
        "fr": "الفرنسية",
        "de": "الألمانية",
        "it": "الإيطالية",
        "hi": "الهندية",
        "cmn": "الصينية",
        "so": "الصومالية",
        "pt": "البرتغالية",
        "id": "الإندونيسية",
        "vi": "الفيتنامية",
        "ko": "الكورية",
        "ja": "اليابانية",
        "ar": "العربية",
        "fil": "الفلبينية",
        "ne": "النيبالية",
        "am": "الأمهرية",
        "ru": "الروسية",
        "uk": "الأوكرانية",
        "ps": "الباشتو",
        "ht": "الكريول الهايتي",
    },
    "fil": {
        "en": "Ingles",
        "es": "Espanyol",
        "fr": "Pranses",
        "de": "Aleman",
        "it": "Italyano",
        "hi": "Hindi",
        "cmn": "Mandarin",
        "so": "Somali",
        "pt": "Portuges",
        "id": "Indones",
        "vi": "Vietnamese",
        "ko": "Koreano",
        "ja": "Hapones",
        "ar": "Arabo",
        "fil": "Pilipino",
        "ne": "Nepali",
        "am": "Amharic",
        "ru": "Ruso",
        "uk": "Ukrainian",
        "ps": "Pashto",
        "ht": "Haitian Creole",
    },
    "ne": {
        "en": "अंग्रेजी",
        "es": "स्पेनिश",
        "fr": "फ्रेंच",
        "de": "जर्मन",
        "it": "इटालियन",
        "hi": "हिन्दी",
        "cmn": "म्यान्डारिन",
        "so": "सोमाली",
        "pt": "पोर्चुगीज",
        "id": "इन्डोनेशियन",
        "vi": "भियतनामी",
        "ko": "कोरियन",
        "ja": "जापानी",
        "ar": "अरबी",
        "fil": "फिलिपिनो",
        "ne": "नेपाली",
        "am": "अम्हेरिक",
        "ru": "रसियन",
        "uk": "युक्रेनियन",
        "ps": "पश्तो",
        "ht": "हैटियन क्रियोल",
    },
    "am": {
        "en": "እንግሊዝኛ",
        "es": "ስፓንሽ",
        "fr": "ፈረንሳይኛ",
        "de": "ጀርመን",
        "it": "ጣሊያን",
        "hi": "ሂንዲ",
        "cmn": "ማንዳሪን",
        "so": "ሶማሊ",
        "pt": "ፖርቱጊዝ",
        "id": "ኢንዶኔዥያ",
        "vi": "ቬትናም",
        "ko": "ኮሪያን",
        "ja": "ጃፓንኛ",
        "ar": "ዓረብኛ",
        "fil": "ፊሊፒኖ",
        "ne": "ኔፓሊ",
        "am": "አማርኛ",
        "ru": "ሩሲያኛ",
        "uk": "ዩክሬኒያን",
        "ps": "ፓሽቶ",
        "ht": "ሀይቲየን ክሪዮሊ",
    },
    "ru": {
        "en": "английский",
        "es": "испанский",
        "fr": "французский",
        "de": "немецкий",
        "it": "итальянский",
        "hi": "хинди",
        "cmn": "китайский",
        "so": "сомалийский",
        "pt": "португальский",
        "id": "индонезийский",
        "vi": "вьетнамский",
        "ko": "корейский",
        "ja": "японский",
        "ar": "арабский",
        "fil": "филиппинский",
        "ne": "непальский",
        "am": "амхарский",
        "ru": "русский",
        "uk": "украинский",
        "ps": "пушту",
        "ht": "гаитянский креольский",
    },
    "uk": {
        "en": "англійська",
        "es": "іспанська",
        "fr": "французька",
        "de": "німецька",
        "it": "італійська",
        "hi": "гінді",
        "cmn": "китайська",
        "so": "сомалійська",
        "pt": "португальська",
        "id": "індонезійська",
        "vi": "в'єтнамська",
        "ko": "корейська",
        "ja": "японська",
        "ar": "арабська",
        "fil": "філіппінська",
        "ne": "непальська",
        "am": "амхарська",
        "ru": "російська",
        "uk": "українська",
        "ps": "пушту",
        "ht": "гаїтянський креольський",
    },
    "ps": {
        "en": "انگریزی",
        "es": "ہسپانوی",
        "fr": "فرانسیسی",
        "de": "جرمن",
        "it": "اطالوی",
        "hi": "ہندی",
        "cmn": "چینی",
        "so": "صومالی",
        "pt": "پرتگالی",
        "id": "انڈونیشیائی",
        "vi": "ویتنامی",
        "ko": "کوریائی",
        "ja": "جاپانی",
        "ar": "عربی",
        "fil": "فلپائنی",
        "ne": "نیپالی",
        "am": "امہری",
        "ru": "روسی",
        "uk": "یوکرینیائی",
        "ps": "پشتو",
        "ht": "ہیٹین کریول",
    },
    "ht": {
        "en": "Angle",
        "es": "Panyòl",
        "fr": "Franse",
        "de": "Alman",
        "it": "Italyen",
        "hi": "Hindi",
        "cmn": "Mandarin",
        "so": "Somali",
        "pt": "Pòtigè",
        "id": "Endonezyen",
        "vi": "Vyetnamyen",
        "ko": "Koreyen",
        "ja": "Japonè",
        "ar": "Arab",
        "fil": "Filipino",
        "ne": "Nepale",
        "am": "Amharik",
        "ru": "Ris",
        "uk": "Ikrenyen",
        "ps": "Pachto",
        "ht": "Kreyòl Ayisyen",
    },



}

export function chatTransFlagDisplay(localization, userLanguage, isOriginal, isRTL, smallGap = false) {

    if (localization && localization.trim() !== "" && localization.includes("-")) {
        let countryCode = localization.split("-")[1].toLowerCase()
        let language = localization.split("-")[0].toLowerCase()
        return <div style={{display: "flex", alignItems: "center", flexDirection: isRTL ? "row-reverse" : "row", gap: smallGap ? 0: 3}}><CircleFlag height="16" style={{marginRight: 5}} countryCode={countryCode}/> {languageNameTranslation(language, userLanguage)}{isOriginal ? "*" : ""}</div>;
    }
    return <div>{localization}</div>;
}

export function languageNameTranslation(language, userLanguage) {

    let userLang = "en-US";
    let localLanguage = "en-US"
    if (userLanguage && userLanguage.trim() !== "" && userLanguage.includes("-")) {
        userLang = userLanguage.split("-")[0].toLowerCase();
        localLanguage = language.split("-")[0].toLowerCase();
    }
    return translationMap[userLang][localLanguage];
}