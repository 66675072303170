import {req_get_all_sessions, req_get_notifications, req_get_session} from "./requests";
import eventBus from "./eventBus";

export function setLocalStorageVals(userObj) {}

export function createChatSession(sessionId) {
    localStorage.setItem("cSessionId", sessionId);
    req_get_session(sessionId)
        .then(function (result) {
            if (result.status === 200) {
                //console.log("assigning object", result)
                localStorage.setItem("cSession", JSON.stringify(result.data.session));
            }
        })
}

export async function createChatSessionAsync(sessionId) {
    localStorage.setItem("cSessionId", sessionId);
    return req_get_session(sessionId)
        .then(function (result) {
            if (result.status === 200) {
                console.log("assigning object", result)
                return result.data.session;
                //localStorage.setItem("cSession", JSON.stringify(result.data.session));
            }
        })
}


export async function sessionReset(sessionId, rebase = true) {
    //console.log("before", sessionId)
    clearChatSession();
    clearChatSessionObject();
    clearChatSessionUser();
    setSuppressRestartState(true);
    setListeningState(false);
    setRecordingState(false);
    createChatSessionId(sessionId).then((sessionObj) => {
        //console.log("sessionObj", sessionObj)

        createChatSessionUser(localStorage.getItem("uid"));
        eventBus.dispatch("triggerChatRefresh", {rerun: false});
        eventBus.dispatch("updateSubmissionContainer", {sessionObj: sessionObj})
        eventBus.dispatch("updateHeader", {rerun: false});
        eventBus.dispatch("updateLanguages", {sessionObj: sessionObj});

        if (rebase) {
            eventBus.dispatch("rebaseChat", {sessionObj: sessionObj});
        }
        //console.log("after")
    })
}


export async function createChatSessionId(sessionId) {
    //console.log("qsessionId", sessionId)
    localStorage.setItem("cSessionId", sessionId);
    return await req_get_session(sessionId)
        .then(function (result) {
            if (result.status === 200) {
                localStorage.setItem("cSession", JSON.stringify(result.data.session));
                console.log("retrieved data", result)
                return result.data.session
            }
        })
}

export function createChatSessionObject(sessionId) {
    //console.log("createChatSessionObject", sessionId)
    req_get_session(sessionId)
        .then(function (result) {
            if (result.status === 200) {
                localStorage.setItem("cSession", JSON.stringify(result.data.session));
                //console.log("call updateSubmissionContainer in session1")
                eventBus.dispatch("updateSubmissionContainer", {sessionObj: result.data.session})
                eventBus.dispatch("updateHeader", {rerun: false});
                eventBus.dispatch("updateLanguages", {sessionObj: result.data.session});
                eventBus.dispatch("triggerChatRefresh", {rerun: false});

            }
        })
}


export async function createChatSessionObject2(sessionId) {
    //console.log("second")
    //console.log("createChatSessionObject", sessionId)
    return await req_get_session(sessionId)
        .then(function (result) {
            if (result.status === 200) {
                //console.log("session loaded", result)
                localStorage.setItem("cSession", JSON.stringify(result.data.session));
                //console.log("call updateSubmissionContainer in session2")
                eventBus.dispatch("updateSubmissionContainer", {sessionObj: result.data.session})
                eventBus.dispatch("updateHeader", {rerun: false});
                eventBus.dispatch("updateLanguages", {sessionObj: result.data.session});
                eventBus.dispatch("triggerChatRefresh", {rerun: false});
                return;
            }
        })
}


export async function loadSessions() {
    req_get_all_sessions()
        .then(function (result) {
            if (result.status === 200) {
                localStorage.setItem("chatSessions", JSON.stringify(result.data.sessions));
                eventBus.dispatch("updateDashboard", {})
            }
        })
}


export async function loadNotifications(reloadOnZero = true) {
    console.log("loadNotifications")
    req_get_notifications()
        .then((response) => {
            if (response.status === 200) {
                //console.log("response.data.notifications", response.data.notifications)
                localStorage.setItem("notifications", JSON.stringify(response.data.notifications));
                if (reloadOnZero && response.data.notifications.length === 0) {
                    eventBus.dispatch("updateNotifications", {})
                } else {
                    eventBus.dispatch("updateNotifications", response.data.notifications)
                }
            }
        })
}


export function createChatSessionUser(userId) {
    localStorage.setItem("cSessionUserId", userId);
}

export function createSelectedAccount(selectedAccountId) {
    localStorage.setItem("selectedAccount", selectedAccountId);
}

export function clearChatSession() {
    localStorage.setItem("cSessionId", "");
}

export function clearChatSessionObject() {
    localStorage.setItem("cSession", "");
}

export function clearChatSessionUser() {
    localStorage.setItem("cSessionUserId", "");
}

export function clearSelectedAccount() {
    localStorage.setItem("selectedAccount", "");
}

export function clearRecordingState() {
    localStorage.setItem("recordingState", "false");
}

export function clearNotifications() {
    localStorage.setItem("notifications", []);
}

export function setSuppressRestartState(val) {
    localStorage.setItem("suppressRestart", val.toString());
}

export function setListeningState(val) {
    localStorage.setItem("listeningState", val.toString());
}

export function setRecordingState(val) {
    localStorage.setItem("recordingState", val.toString());
}

export function setOtherInvokerState(val) {
    localStorage.setItem("otherInvoker", val.toString());
}

export function getSuppressRestartState() {
    return localStorage.getItem("suppressRestart") === "true";
}

export function getListeningState() {
    return localStorage.getItem("listeningState") === "true";
}

export function getMicContious() {
    return localStorage.getItem("micContinuous") === "true";
}


export function getOtherInvokerState() {
    return localStorage.getItem("otherInvoker") === "true";
}






export function languageEval(lang) {
    if (lang === "en") {
        return "en-US"
    } else if (lang === "es") {
        return "es-ES"
    }  else if (lang === "fr") {
        return "fr-FR"
    }
}

